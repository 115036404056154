import React from 'react';

export const H1 = (props: any) => (
  <h1 {...props} className={`font-display text-4xl font-light leading-[1.2em] tracking-[-0.02em] ${props.className || ''}`}>
    {props.children}
  </h1>
);

export const H2 = (props: any) => (
  <h2 {...props} className={`text-[26px] font-medium leading-[1.2em] ${props.className || ''}`}>
    {props.children}
  </h2>
);

export const H3 = (props: any) => (
  <h3 {...props} className={`text-[20px] font-medium leading-[1.1em] ${props.className || ''}`}>
    {props.children}
  </h3>
);

export const H4 = (props: any) => (
  <h4 {...props} className={`text-[17px] leading-[1.2em] ${props.className || ''}`}>
    {props.children}
  </h4>
);

export const H5 = (props: any) => (
  <h5 {...props} className={`text-[16px] leading-[1.1em] font-medium ${props.className || ''}`}>
    {props.children}
  </h5>
);

export const P = (props: any) => (
  <p {...props} className={`text-base leading-tight ${props.className || ''}`}>
    {props.children}
  </p>
);
