import React from 'react';
import { Header } from '../header/header';
import { Footer } from '../footer/footer';
import { useEffect } from 'react';
import {navigateToError} from "@/utils/navigate";


const Layout = ({ children }) => {
    useEffect(() => {
        const interval = setInterval(() => {
            console.log('Token expired');
            navigateToError()?.();
        }, 600000);
        return () => clearInterval(interval);
    }, []);

  return (
    <div className="h-screen bg-white antialiased xs:flex xs:flex-col xs:items-center xs:justify-center xs:bg-primary xs:pt-20 xs:pb-[76px] short:pb-0">
      <div
        className="relative xs:flex xs:max-h-[810px] xs:w-full xs:max-w-[375px] xs:flex-1 xs:flex-col xs:items-center xs:overflow-hidden xs:rounded-[40px] short:max-w-[360px] short:rounded-b-none"
        id="silbo-main"
      >
        <div className="bg-primary safe-top safe-left safe-right safe-bottom xs:flex xs:w-full xs:flex-auto xs:flex-col xs:overflow-y-auto xs:rounded-[40px] xs:scrollbar-hide short:rounded-b-none">
          <div className="flex min-h-screen flex-col overflow-y-scroll bg-white pb-10 xs:min-h-full xs:flex-shrink-0 xs:overflow-y-hidden">
            <Header />
            <div className="mx-auto flex w-full max-w-md flex-auto flex-col items-center justify-between pt-[59px]">
              {children}
            </div>
          </div>
          <Footer />
        </div>
        <div className="xs:pointer-events-none xs:absolute xs:inset-0 xs:z-20 xs:rounded-[40px] xs:border-[3px] xs:border-solid xs:border-white short:rounded-b-none short:border-b-0"></div>
      </div>
      <div className="fixed bottom-0 right-0 hidden text-right xs:pb-[76px] xs:pr-16 lg:block lg:w-72 xl:w-auto">
        <span className="text-[22px] text-white">Tan fácil como hacerlo desde tu móvil.</span>
      </div>
    </div>
  );
};

export default Layout;
