import React, { useState, useEffect, useContext } from 'react';
import { Helmet } from 'react-helmet';
import { getTokenExUrl } from '../../utils/tokenex';
import { HeaderProps } from './models';
import { CompanyContext } from '../../context/company.context';
import { LangContext } from '../../context/lang.context';

const Head = ({ meta = {}, pageTitle }: HeaderProps) => {
  const { displayName, cookieConsentPaths } = useContext(CompanyContext);
  const company = useContext(CompanyContext);
  const language = useContext(LangContext);
  const lang = language?.lang?.slice(0, 2);
  const { DESCRIPTION: descriptionMeta = '' } = meta;
  const [title, setTitle] = useState<string>(displayName || '');

  const loadCookieConsentScript = (callback: any) => {
    const script = document.createElement('script');
    /* generic error page hasn't context */
    if (company && language.lang) script.src = cookieConsentPaths[language.lang];
    script.id = 'cookieConsentScript';
    document.body.appendChild(script);
    script.onload = () => {
      if (callback) callback();
    };
  };

  useEffect(() => {
    pageTitle && setTitle(`${pageTitle} | ${displayName}`);
    const exist = document.getElementById('cookieConsentScript');
    if (!exist && displayName) {
      loadCookieConsentScript(() => {
        (window as any).cookieconsent.run({
          notice_banner_type: 'simple',
          consent_type: 'express',
          palette: 'light',
          website_name: `${displayName}`,
          /* we need to pass it a generic URL to show the link. it takes the real path from internal texts */
          cookies_policy_url: `https://notused.com`,
        });
      });
    }
  }, []);

  return (
    <Helmet>
      <html lang={lang} />
      <title>{title}</title>
      <meta property="og:site_name" content={title} />
      <meta property="og:title" content={title} />
      <meta name="description" content={descriptionMeta} />
      <meta property="og:description" content={descriptionMeta} />
      <meta property="og:type" content="website" />
      <meta property="og:locale" content={lang} />
      <meta name="HandheldFriendly" content="true" />
      <meta name="viewport" content="width=device-width, initial-scale=1.0" />
      <link rel="prefetch" as="script" href={getTokenExUrl() as string} />
    </Helmet>
  );
};

export { Head };
