import { navigate } from 'gatsby';

import { CardInformation, UserDetailInformation } from '../models/context';
import pages from '../constants/pages.json';
import { diffTwoDatesInSeconds, isTrue, isFunction, delay } from './functions';
import { validMultiCompanyPath } from './regexs';

type User = CardInformation | UserDetailInformation;
interface Props {
  callback?: () => void;
  replace?: boolean;
  state?: any;
  timeStamp?: string;
}

const getMultiCompanyPath = (path: string) => {
  //const { pathname } = typeof window !== 'undefined' ? window.location : { pathname: '' };
  //const prefixPathMatch = pathname?.match(validMultiCompanyPath) || [];
  //const prefixPath = prefixPathMatch?.length > 0 ? prefixPathMatch[0] : '';
  const prefixPath = "/company/287634/es-ES"
  return `${prefixPath}${path}`;
}

const navigateWithPath = (path: string, params: any) => navigate(getMultiCompanyPath(path), params);

const navigateToExpired = (user: User, { timeStamp, state }: Props = {}) => {
  const { status, remaningTimeToTimeOut } = user;
  const possibleExpiredErrors = [410];
  const expiredError = [possibleExpiredErrors.some((statusCode: number) => status === statusCode)].every(isTrue);
  const checkErrors = [expiredError, remaningTimeToTimeOut === 0];

  if (!!timeStamp?.length && !!user?.expirationTime?.length) {
    const checkDiff = diffTwoDatesInSeconds(timeStamp, user?.expirationTime ?? '');
    checkErrors.push(!checkDiff);
  }

  const existsExpiredErrors = checkErrors.some(isTrue);

  if (existsExpiredErrors) {
    return () => {
      const { origin } = window.location;
      window.history.replaceState(state, 'state', `${origin}${getMultiCompanyPath(pages.EXPIRED)}`);
      navigateWithPath(pages.EXPIRED, { replace: true, state });
    };
  }
};

const navigateToError = (user?: User) => {
  if (!user) {
    return () => navigateWithPath(pages.ERROR, { replace: true });
  }

  const { status } = user;

  if (!!status) {
    return () => navigateWithPath(pages.ERROR, { replace: true });
  }
};

const navigateToSuccess = ({ state, callback }: Props = {}) => {
  return () => {
    const { origin } = window.location;
    window.history.replaceState(state, 'state', `${origin}${getMultiCompanyPath(pages.SUCCESS)}`);
    navigateWithPath(pages.SUCCESS, { replace: true, state });
    delay(600).then(() => callback?.());
  };
};

const navigateToFailed = ({ state, callback }: Props = {}) => {
  return () => {
    const { origin } = window.location;
    window.history.replaceState(state, 'state', `${origin}${getMultiCompanyPath(pages.FAILED)}`);
    navigateWithPath(pages.FAILED, { replace: true, state });
    delay(600).then(() => callback?.());
  };
};

const redirectWhenStatusPageIsNotValid = (user: User, timeStamp?: string, state: any = {}) => {
  const { expired } = state;
  const redirect = [
    navigateToExpired(user, {
      timeStamp,
      state: expired?.state,
    }),
    navigateToError(user),
  ].find(isFunction);

  redirect?.();
};

const navigateToCustomPage = (page: string, { state, callback, replace = false }: Props = {}) => {
  return () => {
    const { origin } = window.location;
    replace && window.history.replaceState(state, 'state', `${origin}${getMultiCompanyPath(page)}`);
    navigateWithPath(page, { replace, state });
    callback?.();
  };
};

export {
  navigateToCustomPage,
  navigateToError,
  navigateToExpired,
  navigateToFailed,
  navigateToSuccess,
  redirectWhenStatusPageIsNotValid,
  getMultiCompanyPath,
  navigateWithPath,
};
