import React, { useContext } from 'react';
import { navigate } from 'gatsby';
import Layout from '../../../components/287634/layout/layout';
import Button from '../../../components/287634/button/button';
import { H2, H3, P } from '../../../components/287634/typography';
import { CompanyContext } from '../../../context/company.context';

const AddCardPage = ({ search }: PageProps) => {
  const { getPublicURL } = useContext(CompanyContext);

  return (
    <Layout>
      <section className="mx-5 text-center text-primary">
        <H2 className="mt-[60px] mb-10">Realiza la recarga y agrega tu tarjeta</H2>
        <img className="mx-auto mb-10 w-2/3" alt="Card" src={getPublicURL(`/theme/assets/images/card/card.svg`)} />
        <H3 className="mb-5 mx-5">Agrega tu tarjeta e ingresa dinero en tu cuenta Silbo</H3>
        <P className="mb-20 mx-5">
          Estás a un paso de poder enviar dinero a través de WhatsApp. Agrega y valida ahora tu tarjeta de débito.
          Empezaremos cargando el importe ingresado en el chat.
        </P>
      </section>
      <Button
        onClick={() => {
          navigate(`../add-card-payment${search}`);
        }}
      >
        Comenzar
      </Button>
    </Layout>
  );
};

export default AddCardPage;
