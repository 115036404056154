import React, { useContext } from 'react';
import { CompanyContext } from '../../../context/company.context';
import { Head } from '../../header/head';

const Header = () => {
  const { getPublicURL } = useContext(CompanyContext);
  return (
    <header className="fixed z-40 flex h-[60px] w-full items-center justify-center bg-white p-5 drop-shadow-md xs:max-w-[375px] xs:rounded-t-[40px] short:max-w-[360px]">
      <Head />
      <img className="h-5" src={getPublicURL('/theme/assets/images/logo/logo.svg')} alt="Silbo Logo" />
    </header>
  );
};

export { Header };
